import { environment } from '@env'
import { Injectable } from '@angular/core'
import { logger } from 'nx/src/utils/logger'
import { HttpClient } from '@angular/common/http'
import { catchError, map, Observable, of, throwError } from 'rxjs'
import {
  LoginRequestModel,
  UserContactModel,
  UserModel,
} from '@domain/user'
import { GroupModel, GroupRoleModel, UserGroupModel } from '@domain/authorization'
import { StorageService } from '@service/storage'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private storageSVC: StorageService
  ) {
  }

  getUserContacts(activated: boolean = true): Observable<UserContactModel[]> {
    const url = `${environment.apiUrl}/users/contacts?active=${activated}`
    logger.debug('user-service#getUserContacts: url=', url)
    return this.http.get<UserContactModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getActiveUserContacts(): Observable<UserContactModel[]> {
    const url = `${environment.apiUrl}/users/contacts?active=true`
    return this.http.get<UserContactModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  addUserContact(user: UserContactModel): Observable<any> {
    const url = `${environment.apiUrl}/users`
    // return this.http.post<number>(url, user).pipe(
    return this.http.post(url, user).pipe(
      map(value => {
        return value
      })
      // catchError(error => {
      //   logger.error(`Error: $error`)
      //   return of(null)
      // }),
    )
  }

  updateUserContact(user: UserContactModel): Observable<boolean> {
    const url = `${environment.apiUrl}/users`
    // return this.http.put<boolean>(url, user).pipe(
    const res = this.http.put<boolean>(url, user).pipe(
      map(value => {
        return value
      })
    )
    logger.debug('user-service#update user: res=', res)
    return res
    /*
     return this.http.put<boolean>(url, user).pipe(
     catchError(this.handleError('updateUser', user)),
     )
     */
  }

  deleteUsersByIds(ids: number[]): Observable<any> {
    const url = `${environment.apiUrl}/users/${ids}`
    logger.debug('user-service#deleteUsersByIds: ids=', ids, 'url=', url)
    return this.http
               .delete(url)
               .pipe(
                 catchError(this.handleError('deleteUser'))
               )
  }

  getRoles(): Observable<any> {
    const url = `${environment.apiUrl}/users/roles`
    return this.http.get<GroupRoleModel>(url).pipe(
      map(roles => {
        logger.debug(`user-service#getRoles: roles=`, roles)
        if (roles == null) return of(null)
        return roles
      })
    )
  }

  getRolesByUserId(id: number): Observable<GroupRoleModel[]> {
    const url = `${environment.apiUrl}/users/${id}/roles`
    return this.http.get<GroupRoleModel[]>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  getGroups(): Observable<GroupModel[]> {
    const url = `${environment.apiUrl}/users/groups`
    return this.http.get<GroupModel[]>(url).pipe(
      map(value => {
        logger.debug(`user-service#getGroups: groups=`, value)
        // if (value == null) return of(null)
        return value
      })
    )
  }

  getGroupsByUserId(id: number): Observable<GroupModel[]> {
    const url = `${environment.apiUrl}/users/${id}/groups`
    return this.http.get<GroupModel[]>(url).pipe(
      map(value => {
        logger.debug(`user-service#getGroupsByUserId: groups=`, value)
        // if (groups == null) return of(null)
        return value
      })
    )
  }

  login(request: LoginRequestModel) {
    const url = `${environment.apiUrl}/login`
    return this.http.post<UserModel>(url, request).pipe(
      map(value => {
        return value
      })
    )
  }

  /*
   login(request: LoginRequestModel): Observable<UserModel> {
   logger.debug('authService#login: login=', request.login)
   if (this.storageSVC.isLoggedIn(request.login)) {
   logger.debug('authService#login: loggedIn=true, user=', this.user)
   return of(this.user)
   }
   return this.userSVC.login(request)
   }
   */

  logout(userId: number) {
    const url = `${environment.apiUrl}/logout`
    return this.http.post<UserModel>(url, userId).pipe(
      map(value => {
        return value
      })
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<boolean> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead
      // TODO: better job of transforming error for user consumption
      logger.error(`${operation} failed: ${error.message}`)
      // Let the app keep running by returning an empty result.
      return of(false)
    }
  }

}
